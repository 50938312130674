

.chat-application .app-content, .chat-application .content-right, .chat-application .content-wrapper, .chat-application .content-body {
    height: 100%;
}

.chat-application .content-wrapper {
    padding: 0 !important;
}

.chat-application .sidebar-left {
    border-left: 1px solid #E4E7ED;
    z-index: 999;
}

.chat-application .chat-fixed-search {
    position: fixed;
    z-index: 999;
    background: #FFFFFF;
    width: 300px;
    border-bottom: 1px solid #E4E7ED;
}

.chat-application .users-list-padding {
    padding-top: 83px;
    padding-bottom: 60px;
}

.chat-application .chat-app-window {
    padding: 1rem;
    overflow-y: auto;
    text-align: center;
    height: -webkit-calc(100% - 112px);
    height: -moz-calc(100% - 112px);
    height: calc(100% - 112px);
    background-color: #FFFFFF;
}

.chat-application .chat-app-form {
    position: relative;
    padding: 20px 10px;
    background-color: #EDEEF0;
    overflow: hidden;
}

.chat-application .chats {
    padding: 0;
}

    .chat-application .chats .chat-body {
        display: block;
        margin: 10px 0 0 30px;
        overflow: hidden;
    }

        .chat-application .chats .chat-body .chat-content {
            text-align: left;
            position: relative;
            display: block;
            float: left;
            padding: 8px 15px;
            margin: 0 0 10px 20px;
            clear: both;
            background-color: #d5d0b7;
            border-radius: 4px;
        }

    .chat-application .chats .chat-center {
        display: table;
        margin: 10px auto;
    }

        .chat-application .chats .chat-center .chat-body .chat-content {
            background-color: #efefef;
        }

    .chat-application .chats .chat-body .chat-content:before {
        position: absolute;
        top: 10px;
        left: -10px;
        width: 0;
        height: 0;
        content: '';
        border: 5px solid transparent;
        border-right-color: #d5d0b7;
    }

    .chat-application .chats .chat-center .chat-body .chat-content:before {
        display: none;
    }

    .chat-application .chats .chat-body .chat-content:before

    .chat-application .chats .chat-body .chat-content p {
        margin: 0;
    }

    .chat-application .chats .chat-avatar {
        float: left;
    }

.chat-application .btn-see-more {
    color: #404E67;
}

.chat-application .chat-left .btn-see-more {
    color: #404E67;
}

.chat-application .chats .chat-avatar .avatar {
    width: 50px;
    margin-top: -10px;
    display: block;
}

    .chat-application .chats .chat-avatar .avatar img {
        width: 100%;
    }

.chat-application .chats .time {
    color: #BABFC7;
    font-size: 12px;
    text-align: center;
    margin: 40px 0;
}

.chat-application .chats .chat-left .chat-avatar {
    float: right;
}

.chat-application .chats .chat-left .chat-body {
    margin-left: 0;
    margin-right: 30px;
}

.chat-application .chats .chat-left .chat-content {
    text-align: right;
    float: right;
    margin: 0 20px 10px 0;
    color: #404E67;
    background-color: #EDEEF0;
}

    .chat-application .chats .chat-left .chat-content + .chat-content:before {
        border-color: transparent;
    }

    .chat-application .chats .chat-left .chat-content:before {
        left: auto;
        right: -10px;
        border-left-color: #EDEEF0;
        border-right-color: transparent;
    }

.chat-application .form-control-position {
    cursor: pointer;
}

    .chat-application .form-control-position.control-position-right {
        left: 18px;
        top: 2px;
        cursor: pointer;
    }

.chat-application .chat-attachments .box-file {
    box-shadow: unset;
    max-width: unset;
    width: auto;
    padding: .3rem;
    font-size: .7rem;
    line-height: 1rem;
    border: none;
}

.chat-application .message-date {
    font-style: italic;
    font-size: .8rem;
}

.chat-application hr {
    border-top: 1px solid rgba(0,0,0,.2);
}

.chat-application .chat-left hr {
    border-top: 1px solid rgba(0,0,0,.2);
}


@media (max-width: 767.98px) {
    .chat-application .chat-app-window {
        height: -webkit-calc(100% - 132px);
        height: -moz-calc(100% - 132px);
        height: calc(100% - 132px);
    }
}
