html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px;
  color: #555;
}

@media (min-width: 48em) {
  html {
    font-size: 16px;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #333;
}


body > .container {
  padding: 10px 15px 10px;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.clear {
	clear: both;
	margin-bottom: 20px;
}

label {
    margin-bottom: .2rem;
	font-weight: 500;
}
	
.login-menu {
  margin-bottom: 3rem;
  background-color: #4b698a;
  -webkit-box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
  box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
}
.login-menu img{
    height: 45px;
}
/* Nav links */
.nav-link {
  position: relative;
  padding: 1rem;
  font-weight: 500;
  color: #cdddeb;
}

.nav-link:hover,
.nav-link:focus {
  color: #fff;
  background-color: transparent;
}

/* Active state gets a caret at the bottom */
.nav-link.active {
  color: #fff;
}
.nav-link.active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -.3rem;
  vertical-align: middle;
  content: "";
  border-right: .3rem solid transparent;
  border-bottom: .3rem solid;
  border-left: .3rem solid transparent;
}


/* Footer */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}
.card-login {
    width: 100%;
    max-width: 28rem;
}
.album .card-img-top {
    max-height: 200px;
}

.gallery-carousel {
    max-height: 200px;
}

.car-type-carousel .carousel-indicators {
    margin-bottom: 0;
    bottom: 0;
}

.lightbox-wrap {
    position: relative;
}

    .lightbox-wrap a {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: black;
    }

.gallery-carousel .carousel-indicators {
    margin-bottom: 0;
    bottom: -10px;
}

    .car-type-carousel .carousel-indicators li,
    .gallery-carousel .carousel-indicators li {
        background-color: rgba(0,0,0,.5);
    }

        .car-type-carousel .carousel-indicators li:hover,
        .gallery-carousel .carousel-indicators li:hover {
            cursor: pointer;
        }

        .car-type-carousel .carousel-indicators li.active,
        .gallery-carousel .carousel-indicators li.active {
            background-color: #000;
        }

        .car-type-carousel.carousel-indicators li::after {
            bottom: 0
        }

.car-type-carousel .carousel-control-prev-icon, .car-type-carousel .carousel-control-next-icon {
    color: black;
    background-image: none;
}
.ck-editor__editable {
    min-height: 200px;
}